import { RichText } from 'prismic-reactjs';
import React from 'react';
import '../../ourstory.scss';
import TimelineLargeItem from './timelineLargeItem';

const TimelineLarge = (props) => {
  const { timelineData } = props;

  const seventies = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "1970's"
  );
  const eighties = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "1980's"
  );
  const nineties = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "1990's"
  );
  const thousands = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "2000's"
  );
  const thousandTens = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "2010's"
  );
  const thousandTwenties = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "2020's"
  );

  return (
    <div className='desktop-timeline container'>
      <div className='columns'>
        <TimelineLargeItem
          contentClassName='content-seventiesLg'
          data={seventies}
        />
        <div className='column'></div>
        <TimelineLargeItem
          contentClassName='content-ninetiesLg'
          data={nineties}
        />
        <div className='column'></div>
        <TimelineLargeItem
          contentClassName='content-twothousandtensLg'
          data={thousandTens}
        />
        <div className='column'></div>
      </div>
      <div className='columns'>
        <div className='column history-year year-seventies py-4'>
          {RichText.asText(seventies.primary.decade_year.richText)}
        </div>
        <div className='column history-year year-eighties py-4'>
          {RichText.asText(eighties.primary.decade_year.richText)}
        </div>
        <div className='column history-year year-nineties py-4'>
          {RichText.asText(nineties.primary.decade_year.richText)}
        </div>
        <div className='column history-year year-twothousands py-4'>
          {RichText.asText(thousands.primary.decade_year.richText)}
        </div>
        <div className='column history-year year-twothousandtens py-4'>
          {RichText.asText(thousandTens.primary.decade_year.richText)}
        </div>
        <div className='column history-year year-seventies py-4'>
          {RichText.asText(thousandTwenties.primary.decade_year.richText)}
        </div>
      </div>
      <div className='columns'>
        <div className='column'></div>
        <TimelineLargeItem
          contentClassName='content-eightiesLg'
          data={eighties}
        />
        <div className='column'></div>
        <TimelineLargeItem
          contentClassName='content-twothousandsLg'
          data={thousands}
        />
        <div className='column'></div>
        <TimelineLargeItem
          contentClassName='content-seventiesLg'
          data={thousandTwenties}
        />
      </div>
    </div>
  );
};

export default TimelineLarge;
