import { RichText } from 'prismic-reactjs';
import React from 'react';
import '../../ourstory.scss';
import '../../ourstory_small.scss';

const TimelineSmallItem = (props) => {
  const { contentClassName, yearClassName, isItemsOnTop, data } = props;

  const fieldsContent = data.items.map((field, index) => {
    const descriptionField = field.description;
    const yearField = field.year;

    const description = RichText.asText(descriptionField.richText);
    const year = yearField ? RichText.asText(yearField.richText) : '';

    return (
      <div key={index} className='column text-gray-700'>
        <span>{`${year ? year + ': ' + description : description}`}</span>
      </div>
    );
  });

  const decadeYearContent = (
    <div className={`column history-year py-4 ${yearClassName}`}>
      <span>{RichText.asText(data.primary.decade_year.richText)}</span>
    </div>
  );

  return (
    <div
      className={`column is-one-fifth history-parent-box ${contentClassName}`}
    >
      <div
        className={`columns item-container ${isItemsOnTop ? 'top' : 'bottom'}`}
      >
        {isItemsOnTop ? (
          <>
            {fieldsContent}
            {decadeYearContent}
          </>
        ) : (
          <>
            {decadeYearContent}
            {fieldsContent}
          </>
        )}
      </div>
    </div>
  );
};

export default TimelineSmallItem;
