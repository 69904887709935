import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { htmlSerializer } from '../utils/htmlSerializer';
import TimelineSmall from '../components/about-us/timelineSmall';
import Layout from '../components/layout';
import FullWidthContainer from '../components/containers/fullWidthContainer';
import HeroContent from '../components/heroContent';
import { linkResolver } from '../utils/linkResolver';
import TimelineLarge from '../components/about-us/timelineLarge';
import LeftDownSlantCallToActionContainer from '../components/containers/LeftDownSlantCallToActionContainer';
import SectionTitle from '../components/sectionTitle';
import FullWidthImageCollection from '../components/images/fullWidthImageCollection';
import { convertToBgImage } from 'gbimage-bridge';
import { RichText } from 'prismic-reactjs';

const AboutUsPage = ({ data }) => {
  const {
    hero_title,
    banner_graphic,
    banner_image,
    banner_description_content,
    body,
    section_timeline_title,
    section_title,
    section_text,
    next_page_link,
    bottom_images,
    call_to_action_link_text,
  } = data.prismicAboutUs.data;

  const backgroundImage = convertToBgImage(banner_image.gatsbyImageData);
  const backgroundImageFluidStack = [`${banner_graphic.url}`, backgroundImage];

  const ctaLink = linkResolver(next_page_link);
  const callToActionText = RichText.asText(call_to_action_link_text.richText);

  const title = RichText.asText(section_title.richText);
  const timelineTitle = RichText.asText(section_timeline_title.richText);

  return (
    <Layout
      pageTitle={RichText.asText(hero_title.richText)}
      backgroundImageFluidStack={backgroundImageFluidStack}
      logoClassName='max-w-xxxxs xl:max-w-xxxs mega:max-w-xxs'
      heroSlantClassName='bottom-left-slant-down-right'
      useTallBannerBackground
      contentInsideBackground={() => (
        <FullWidthContainer className='mb-4 xl:mt-6 mega:mt-16'>
          <HeroContent
            heroTitle={RichText.asText(hero_title.richText)}
            heroDescription={banner_description_content.richText}
            rightAlign
          />
        </FullWidthContainer>
      )}
    >
      <SectionTitle text={title} usePrimaryTextColor />
      <div className='left-angled-down-right mb-4 flex flex-col bg-denimblue px-6 pt-16 pb-16 text-left leading-relaxed text-white md:px-24 lg:mb-16 lg:px-32 lg:py-32 mega:py-40'>
        <div className='mx-auto max-w-5xl'>
          <PrismicRichText
            field={section_text.richText}
            components={htmlSerializer}
          />
        </div>
      </div>
      <div className='left-angled-down-right slant-margin-sm bg-gray-100 pt-16 pb-24 lg:pt-24'>
        <SectionTitle text={timelineTitle} usePrimaryTextColor />
        <div className='block md:hidden'>
          <TimelineSmall timelineData={body} />
        </div>
        <div className='hidden md:block'>
          <TimelineLarge timelineData={body} />
        </div>
      </div>
      <div className='py-2'>
        <FullWidthImageCollection
          className='bottom-left-slant-down-right lg:mb-12'
          images={bottom_images}
        />
      </div>
      <LeftDownSlantCallToActionContainer
        ctaUrl={ctaLink.url}
        ctaText={callToActionText}
      />
    </Layout>
  );
};

export const query = graphql`
  query AboutUsPageQuery {
    prismicAboutUs {
      data {
        banner_description_content {
          richText
        }
        banner_graphic {
          gatsbyImageData
          alt
          url
        }
        banner_image {
          alt
          gatsbyImageData
        }
        bottom_images {
          image {
            alt
            gatsbyImageData
          }
        }
        call_to_action_link_text {
          richText
        }
        hero_title {
          richText
        }
        next_page_link {
          url
          uid
        }
        section_text {
          richText
        }
        section_timeline_title {
          richText
        }
        section_title {
          richText
        }
        body {
          ... on PrismicAboutUsDataBodyTimelineSlice {
            id
            primary {
              decade_year {
                richText
              }
            }
            items {
              year {
                richText
              }
              description {
                richText
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutUsPage;
