import { RichText } from 'prismic-reactjs';
import React from 'react';
import '../../ourstory.scss';
import '../../ourstory_small.scss';

const TimelineLargeItem = (props) => {
  const { contentClassName, data } = props;

  const fieldsContent = data.items.map((field, index) => {
    const descriptionField = field.description;
    const yearField = field.year;

    const description = RichText.asText(descriptionField.richText);
    const year = yearField ? RichText.asText(yearField.richText) : '';

    return (
      <div key={index} className='column text-gray-700'>
        <span>{`${year ? year + ': ' + description : description}`}</span>
      </div>
    );
  });

  return (
    <div className={`column ${contentClassName}`}>
      <div className='columns item-container'>{fieldsContent}</div>
    </div>
  );
};

export default TimelineLargeItem;
