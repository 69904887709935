import { RichText } from 'prismic-reactjs';
import React from 'react';
import '../../ourstory.scss';
import '../../ourstory_small.scss';
import TimelineSmallItem from './timelineSmallItem';

const TimelineSmall = (props) => {
  const { timelineData } = props;

  const seventies = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "1970's"
  );
  const eighties = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "1980's"
  );
  const nineties = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "1990's"
  );
  const thousands = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "2000's"
  );
  const thousandTens = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "2010's"
  );
  const thousandTwenties = timelineData.find(
    (t) => RichText.asText(t.primary.decade_year.richText) === "2020's"
  );

  return (
    <div className='mobile-timeline container'>
      <div className='columns'>
        <TimelineSmallItem
          contentClassName='content-seventies'
          yearClassName='year-seventies'
          isItemsOnTop={true}
          data={seventies}
        />
        <TimelineSmallItem
          contentClassName='content-eighties'
          yearClassName='year-eighties'
          isItemsOnTop={false}
          data={eighties}
        />
        <TimelineSmallItem
          contentClassName='content-nineties'
          yearClassName='year-nineties'
          isItemsOnTop={true}
          data={nineties}
        />
        <TimelineSmallItem
          contentClassName='content-twothousands'
          yearClassName='year-twothousands'
          isItemsOnTop={false}
          data={thousands}
        />
        <TimelineSmallItem
          contentClassName='content-twothousandtens'
          yearClassName='year-twothousandtens'
          isItemsOnTop={true}
          data={thousandTens}
        />
        <TimelineSmallItem
          contentClassName='content-seventies'
          yearClassName='year-seventies'
          isItemsOnTop={false}
          data={thousandTwenties}
        />
      </div>
    </div>
  );
};

export default TimelineSmall;
