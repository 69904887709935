import React from 'react';
import LinkButton from '../linkButton';

const LeftDownSlantCallToActionContainer = ({ ctaUrl, ctaText }) => {
  return (
    <div className='left-angled-down-right slant-margin-sm mb-8 flex flex-col items-center bg-denimblue pt-8 text-center text-white md:px-8'>
      <div className='mt-12 mb-24 w-full py-3 text-center md:w-1/2 lg:w-1/3 lg:py-10 mega:py-12'>
        <LinkButton to={ctaUrl} label={ctaText} />
      </div>
    </div>
  );
};

export default LeftDownSlantCallToActionContainer;
